import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogContent, 
  Button, 
  Typography, 
  Avatar, 
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Paper
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';
import AgentImg from '../Images/support.jpg';

const PopupComponent = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Detect if the screen is mobile size

  useEffect(() => {
    if (isMobile) { // Show popup only if the screen is mobile size
      const timer = setTimeout(() => setOpen(true), 1000);
      return () => clearTimeout(timer);
    }
  }, [isMobile]);

  const handleClose = () => setOpen(false);

  if (!isMobile) {
    return null; // Don't render anything if not mobile view
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      PaperProps={{
        style: {
          borderRadius: fullScreen ? 0 : '16px',
          margin: fullScreen ? 0 : 32,
          maxWidth: fullScreen ? '100%' : '450px',
          width: '100%',
          height: fullScreen ? '100%' : 'auto',
        },
      }}
    >
      <Paper 
        elevation={0}
        sx={{
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
          py: 3,
          position: 'relative',
          borderRadius: fullScreen ? 0 : '16px 16px 0 0',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'primary.contrastText',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column"
        sx={{
            height: '50px',
            padding: '8px 0',
            margin: '0',
            lineHeight: '1.2',
          }}
        >
          <Typography variant="h5" component="div" align="center" fontWeight="bold">
            TRAVEL RESERVATIONS !
          </Typography>
          <Typography variant="subtitle1" align="center">
            Exclusive Deals Await!
          </Typography>
        </Box>
      </Paper>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: 4 }}>
      <a href="tel:18885086792" style={{textDecoration:'none'}}>
        <Avatar 
          alt="Support Agent" 
          src={AgentImg}
          sx={{ width: 110, height: 110, mb: 3 }}
        />
        </a>
        <a href="tel:18885086792" style={{textDecoration:'none'}}>
        <Typography variant="h5" color="primary" gutterBottom fontWeight="bold">
          24/7 CUSTOMER SUPPORT
        </Typography>
        </a>
        <a href="tel:18885086792" style={{textDecoration:'none'}}>
        <Typography variant="body1" align="center" mt={6} sx={{color:'#d97930', fontSize:'21px'}}>
          Our travel experts are ready to assist you with exclusive phone-only offers!
        </Typography>
        </a>
        <a href="tel:18885086792" style={{textDecoration:'none'}}>
        <Button
          variant="contained"
          color="success"
          startIcon={<PhoneIcon />}
          size="large"
          sx={{
            mt: 28,
            py: 1.5,
            borderRadius: 2,
            fontSize: '1.3rem',
            fontWeight: 'bold',
            boxShadow: 3,
          }}
        >
          (888) 508-6792
        </Button>
        </a>
      </DialogContent>
      
      <div className='cta' style={{backgroundColor:'#1975d1', width:'100%', height:'60px'}}>
      <a href="tel:18885086792" style={{textDecoration:'none'}}>
            <Typography variant='body2' sx={
                {display:'flex',
                color:'white',
                alignItems:'center',
                justifyContent:'center',
                marginTop:'16px',
                fontSize:'22px',
                fontWeight:'700'
                }}>
                <PhoneIcon /> (888) 508-6792
            </Typography>
            </a>
        </div>
    </Dialog>
  );
};

export default PopupComponent;